export const m = {
  Header: {
    coffe_community: '咖啡社区',
    Switch_merchant_side: '切换商家端',
    APP_Download: 'APP下载',
    Official_WeChat: '官方微信',
    customer_service: '官方客服',
    home_page: '首页',
    mobile_terminal: '移动端',
    login_again: '重新登录'
  },
  index: {
    quick_login: '快速登录',
    Login_to_view_prices: '登录查看价格',
    Port_of_Kunshan: '昆山港',
    New_York_Futures: '纽约期货',
    Jumbo_Futures: '大版期货',
    Singapore_Commodities: '新加坡商品',
    London_futures: '伦敦期货',
    product_name: '商品名称',
    transaction_price: '成交价格',
    turnover: '成交量',
    region: '地区',
    Transaction_time: '成交时间',
    No_transaction_dynamics: '暂无交易动态！',
    Quick_buy: '快捷买入',
    Estate_presale: '庄园预售',
    Next_estate: '下一个庄园',
    Pre_sale_name: '预售商品名称：',
    price: '价格',
    capacity: '产能',
    Founders_Day: '创建日',
    reserve: '预 定',
    information_center: '资讯中心',
    more: '更多',
    No_information_available: '暂无资讯',
    ranking_list: '排行榜',
    Variety_transaction_volume: '品种成交额',
    Heat_Ranking: '热度排行榜',
    Latest_launch: '最新上线',
    ranking: '名次',
    variety: '品种',
    Transaction_volume: '成交额',
    inventory: '库存量',
    No_transaction: '没有交易',
    today: '当日',
    this_week: '本周',
    this_month: '本月',
    accumulate: '累计',
    Global_high_supplier_recommendation: '全球优质合作商',
    cooperating_suppliers_with_categories: '7+合作供应商*品类齐全',
    Live_broadcast_hall: '直播大厅',
    no_live_information_available: '暂无直播信息',
    Presale_hall: '预售大厅',
    spot_market: '现货大厅',
    Interactive_warehouse: '交互仓库',
    logistics_service: '物流服务',
    sales_volume: '销量',
    expiration_date: '截止日期',
    no_presale_information_available: '暂无预售信息',
    no_data_spot_commodity: '暂无现货信息',
    Enter_auction_hall: '拍卖大厅',
    trade_name: '商品名',
    Latest_price: '最新价',
    name: '名称',
    starting_price: '起拍价',
    yuan: '元',
    Delivery_Date: '交货日期',
    Minimum_markup: '最小加价',
    bidding: '出价',
    start_time: '开始时间',
    Barter_hall: '易货大厅',
    existing: '现有',
    target: '目标',
    no_barter_information_available: '暂无易货信息',
    company_address: '公司地址',
    company_address_content: '昆山开发区新南东路356号1层（综保区商务大厦2栋）',
    fixed_line_telephone: '服务热线',
    ccdt: '中咖数科（昆山）有限公司',
    All_right_reserved: '版权所有',
    Business_license: '经营性许可证'

  },
  search: {
    product: '产品',
    supplier: '供应商',
    search: '搜索',
    commodity: '商品',
    Today_Headlines: '今日头条',
    Capacity_presale: '产能预售',
    Spot_listing: '现货挂牌',
    barter: '易货',
    region: '专区',
    bidding: '拍卖',
    live_streaming: '直播',
    login: '登录',
    transaction_rules: '交易规则',
    Instructions_for_membership: '入会须知',
    user_manual: '用户手册',
    log_off: '退出登录',
    user_center: '用户中心'

  },
  loginandregister: {
    Account_login: '账号登录',
    username: '用户名',
    password: '密码',
    Please_enter_username: '请输入用户名',
    Please_enter_password: '请输入密码',
    Login_immediately: '立即登录',
    free_registration: '免费注册',
    forget_password: '忘记密码',
    register_account: '注册账号',
    Company_Name: '企业全称',
    Please_enter_Company_Name: '请输入企业全称',
    phone_number: '手机号',
    Contact: '联系人',
    Please_enter_Contact: '请输入联系人',
    auth_code: '验证码',
    Please_enter_phone_number: '请输入手机号',
    TestGetCode: '获取验证码',
    Please_enter_auth_code: '请输入验证码',
    second: '秒',
    regain: '重新获取',
    Please_code_again: '请重新获取验证码',
    Set_Password: '设置密码',
    Please_set_let_num: '请设置8-16个字母+数字',
    account_type: '账户类型',
    buyer: '买家',
    seller: '卖家',
    Confirm_Password: '确认密码',
    Read_and_agree: '阅读并同意',
    User_registration_protocol: '用户注册协议',
    privacy_policy: '隐私政策',
    Register_Now: '立即注册',
    Registered_to_login: '已注册去登陆',
    phonenumber_incorrect: '手机号码不正确',
    Retrieve_password: '找回密码',
    next_step: '下一步',
    reset_password: '重置密码',
    confirm: '确定',
    Two_passwords: '两次密码不同'

  },
  reservation: {
    Presale_transaction_process: '预售交易流程',
    Transaction_dynamics: '成交动态',
    product_name: '产品名称',
    time: '时间',
    turnover: '成交量',
    reset: '重置',
    selected: '已选',
    variety: '品种',
    Maragogype: '象豆',
    Villa_sarchi: '薇拉莎奇',
    Pacas: '帕卡斯',
    Caturra: '卡杜拉',
    bourbon: '波旁',
    Typica: '铁皮卡',
    sales_volume: '销量',
    Sales_in_descending_order: '销量降序',
    in_ascending_order_of_sales_volume: '销量升序',
    Pre_sale: '预售',
    Deadline: '截止日期',
    Price: '价格',
    Price_in_descending_order: '价格降序',
    Price_in_ascending_order: '价格升序',
    No_data_found: '没有找到数据'
  },
  cashcommodity: {
    Spot_transaction_process: '现货交易流程',
    spot_goods: '现货'
  },
  barter: {
    news: '资讯',
    Barter_transaction_process: '易货交易流程',
    existing_products: '现有商品',
    Name: '名称',
    inventory: '库存',
    intended_exchange_commodity: '拟易商品'
  },
  auction: {
    Bidding_process: '拍卖流程',
    My_auction: '我的拍卖',
    Auction_mode: '拍卖模式',
    English_Auction: '咖啡拍卖',
    Fine_English_auction: '精品英式竞拍',
    Dutch_auction: '荷兰式竞拍',
    all_products: '全部商品',
    start_time: '开始时间',
    end_time: '结束时间',
    ton: '吨',
    offers: '次出价',
    commodity_information: '商品信息',
    quantity: '数量',
    amount: '金额',
    state: '状态',
    operate: '操作',
    My_bid: '我的竞拍',
    In_the_auction: '参拍中',
    Successful_auction: '竞拍成功',
    Failed_auction: '竞拍失败'
  },
  live: {
    download: '下载',
    Go_live: '开播',
    help: '帮助',
    report_an_offense: '举报',
    Wonderful_live_streaming: '火爆直播',
    hosts_are_live_streaming: '个主播正在直播',
    Much_more: '更多',
    No_live_streaming_information_available: '暂无直播信息'
  },
  reservation_varietiy_details: {
    Price: '价 格:',
    Buyer_priority_to_pay: '买家优先支付',
    deposit: '订金',
    Stock: '库 存:',
    Minimum_Order_Quantity: '最小订货量',
    Validity_of_Quotation: '报价有效期:',
    Minimum_Increment: '最小增货量',
    Advanced_days_of_delivery: '提前预提天数',
    day: '天',
    Resell_allowed: '允许转售',
    Resale_not_allowed: '不允许转售',
    Permission_to_resell_before_advance_delivery: '可在预提前进行转售',
    Selective_specification: '选择规格:',
    guigeName: '名称',
    guigeStock: '库存',
    guigePrice: '价格',
    guigepickup_date: '提货日期',
    Date_of_Taking_Delivery: '提货日期:',
    Delivery_width: '100px',
    Select_the_date_of_taking_delivery: '选择提货日期',
    number: '数量:',
    Estimated_price: '预估价格',
    est_select_specifications: '选择规格',
    Please_select_specifications: '请选择规格',
    specification: '规格',
    delivery_day: '交货日',
    Purchased_weight: '购买重量',
    Delivery_method: '预提方式',
    Select_withholding_mode: '选择预提方式',
    Prepayment_ratio: '预付比例',
    pick_up_goods_cost: '预提费',
    advance_payment: '预付款',
    final_payment: '尾款',
    Estimated_total_payment: '预计总货款',
    attention: '关注',
    attentionLeft: '110px',
    purchase: '购买',
    purchaseLeft: '33px',
    Supplier_file: '供应商档案',
    followed: '已关注',
    inquiry: '询盘',
    Coffee_details: '咖啡详情',
    Product_number: '产品编号',
    variety: '品种',
    farm: '庄 园',
    place_of_origin: '产 地',
    treeSpecies: '树种',
    year: '年 份',
    elevation: '海 拔',
    Picking_method: '采摘方式',
    way_of_quoting: '报价方式',
    processing_method: '加工方式',
    Coffee_flavor: '咖啡风味',
    brand: '品牌',
    date_issued: '上架时间',
    storage_life: '保存期限',
    place_of_dispatch: '发货地',
    delivery_points: '交货地点',
    commodity_details: '商品详情'
  },
  barter_detail: {
    Self_estimated_price: '自估价格:',
    Delivery_width: '100px',
    period_of_validity: '有效期:',
    place_of_delivery: '交货地:',
    Conversion_ratio: '量换比:',
    Logistics_compensation: '物流补偿金:',
    handling_costs: '装卸补偿金:',
    Advance_deposit: '预交保证金',
    To_generate_an_order: '去摘单',
    Pseudo_easy_price: '拟易价格',
    want_to_trade_inventory: '拟易库存',
    Estimate_whether_it_will_be_able_to_trade: '拟易预估',
    Predict_whether_the_goods_will_be_traded: '拟易商品预估',
    You_can_barter_successfully: '可以易货成功！',
    Possible_unsuccessful_barter: '可能易货不成功！'
  },
  auction_englishauction: {
    day: '天',
    hour: '时',
    minute: '分',
    second: '秒',
    Delivery_width: '120px',
    Delivery_Quantity_width: '140px',
    bidding_is_ready_to_begin: '待竞拍开始:',
    Bidding_in_progress: '正在竞拍:',
    Unsettled: '未成交:',
    traded: '已成交:',
    finished: '已结束',
    completed: '已完成',
    From_the_begin_there_only_one_left: '距离开始仅剩',
    The_end_of_the_distance_is_only_left: '距离结束仅剩',
    product_name: '商品名称',
    present_price: '当前价格',
    Quantity_sold: '数量',
    Deposit_required: '需交保证金',
    bid: '出价',
    Current_price: '当前价',
    purchase_quantity: '抢拍量',
    Likelihood_of_snatched: '抢拍',
    Bidding_rules: '竞拍规则',
    Scan_code_to_download_APP: '扫码下载APP',
    price_cut: '降价幅度',
    Minimum_markup_unit: '最小加价单位',
    sec: '次',
    Minimum_purchase_quantity: '最小起拍量',
    Minimum_increase: '最小加拍量',
    deferred_cycle: '延迟周期',
    If_the_auction_the__days: '如果竞拍失败，保证金将在1-3个工作日退回支付账户',
    If_user_bids_the_bidding_extended: '竞拍最后{time}分钟内若有用户出价，进入延时竞价周期',
    Bid_record: '出价记录',
    Bidding_specification: '竞拍说明',
    top_price: '最高价',
    price: '价格',
    Time: '时间',
    bidder: '竞拍人',
    Dutch_bidding_rules: '荷兰式竞价规则：荷兰式竞价是拍库存（库存拍完为止）。荷兰式的竞价的价格会根据后台维护的降价幅度下降，下降到后台维护的最低价为止。荷兰式竞价库存拍卖完后生成订单或是竞价已结束会根据成交比生成订单。生成的订单会按照竞拍成功的最低价生成订单，同时会退还多付的定金。如竞价失败未生成订单，退还定金。',
    English_bidding_rules: '英式竞价规则：出价高者得。出价成功后，英式竞价结束后会根据成交比生成订单，生成的订单会按照竞拍成功的最低价生成订单，同时会退还多付的定金。英式竞价失败（未生成订单），退还定金。'

  },
  map: {
    Click_on_the_city_to_view_the_goods_under_the_city: '点击城市，可查看城市下的商品',
    Quick_trade: '快捷交易',
    stock: '库存量',
    name: '名称',
    price: '价格',
    quantity: '数量',
    amount_of_increase: '涨幅',
    operation: '操作',
    variety: '品种',
    // stock: 'stock',
    buy: '买入',
    sale: '卖出',
    Presale_information: '预售信息',
    presell: '预售',
    resell: '转售',
    merchant: '商家',
    warehouse: '仓库',
    load_more: '加载更多',
    buying_price: '买入价格',
    Quantity_in_transit: '在转数量',
    Sellable_quantity: '可卖数量',
    Maximum_available: '最大可买',
    aggregate_amount: '总金额',
    Deposit_payable: '应付定金',
    I_have_read_and_agree: '我已阅读并同意',
    purchases_and_sales_contract: '购销合同',
    trade_rules: '贸易规则',
    quality_standard: '质量标准',
    cancel: '取消',
    confirm: '确定',
    Transferred_out: '已转出',
    Selling_price: '出售单价',
    Resale_expiration_date: '转售到期日'

  },
  buy: {
    Confirm_receipt_information: '确认收货信息',
    commodity_information: '商品信息',
    commercial_specification: '商品规格',
    unit_price: '单价',
    quantity: '数量',
    sample_stock: '样品库存',
    sample_quantity: '样品数量',
    total_prices: '总价',
    Advance_the_delivery_date: '预提日期',
    Delivery_type: '提货类型',
    invoice_information: '发票信息',
    Invoice_type: '发票类型',
    invoice_title: '发票抬头',
    check_taker: '收票人',
    Ticket_collection_telephone: '收票电话',
    Receipt_address: '收票地址',
    unfold: '展开',
    put_away: '收起',
    View_all: '查看全部',
    Tariff_Item: '税号',
    Additional_invoice: '增加发票',
    amend_an_invoice: '修改发票',
    Deletion_of_invoice: '删除发票',
    plain_invoice: '普通发票',
    vat_special_invoice: '增值税专用发票',
    Please_enter_the_invoice_title: '请输入发票抬头',
    taxpayer_identification_number: '纳税人识别号',
    Please_enter_taxpayer_identification_number: '请输入纳税人识别号',
    Please_enter_check_taker: ' 请输入收票人',
    Please_enter_Ticket_collection_telephone: '请输入收票电话',
    Please_enter_Receipt_address: '请输入收票地址',
    Set_as_the_default_invoice: '设为默认发票(提醒：每次下单会默认推荐该发票)',
    Save_and_Use_plain: '保存并使用（普通）',
    deposit_bank: '开户银行',
    Please_enter_deposit_bank: '请输入开户银行',
    account_number: '开户账号',
    Please_enter_account_number: '请输入开户账号',
    Account_phone_number: '开户手机号',
    Please_enter_Account_phone_number: '请输入开户手机号',
    business_address: '经营地址',
    Please_enter_business_address: '请输入经营地址',
    Save_and_Use: '保存并使用',
    Please_make_sure_it_is_before_the_delivery_date: '请务必在交货日前',
    days_in_advance_of_delivery: '天进行预提',
    aggregate: '总计',
    Total_amount_payable: '应付总金额',
    Finance_purchase: '融资购买',
    outright_purchase: '直接购买',
    Type_of_delivery: '提货类型',
    logistics: '物流',
    Take_their: '自提',
    Delivery_only: '只配送',
    place_of_delivery: '提货地点',
    Domestic_distribution_only: '只配送国内',
    shipping_address: '收货地址',
    modification: '修改',
    delete: '删除',
    add_shipping_address: '新增收货地址',
    Name_of_the_consignee: '收货人姓名',
    Please_enter_Name_of_the_consignee: '请输入收货人姓名',
    Mobile_phone_number: '手机号码',
    Please_enter_Mobile_phone_number: '请输入手机号码',
    Full_address: '详细地址',
    Please_enter_Full_address: '请输入详细地址',
    Are_you_sure_you_want_to_delete_this_address: '您确定删除此地址吗?'
  },
  buy_settleaccounts: {
    Please: '请您在',
    Please_complete: '内完成支付，否则订单会被自动取消',
    Amount_due: '应付金额',
    Refundable_amount: '应退金额',
    Payment_Methods: '支付方式',
    Balance_payment: '余额支付',
    Payment_password: '支付密码',
    Please_enter_a_6_digit_payment_PIN: '请输入6位支付密码',
    pay: '支付',
    You_have_paid_successfully: '您已付款成功'

  },
  paySuccess: {
    Your_payment_has_been_successful: '您已付款成功',
    Actual_payment: '实付款',
    view_orde_details: '查看订单详情'
  },
  personalcenter_myorder: {
    ctleftwidth: '',
    personal_data: '个人资料',
    Personal_Information: '个人信息',
    Personal_Information_Details: '个人信息详情',
    My_concern: '我的关注',
    Product_fucos: '商品关注',
    Store_attention: '店铺关注',
    Browsing_history: '浏览记录',
    User_Name: '用户名称',
    Mobile_Number: '手机号码',
    E_mail: '邮箱',
    amend: '修改',
    bind: '绑定',
    confirm: '确认',
    change_information: '修改信息',
    Created_in: '创建时间',
    // 点开商品关注按钮之后的页面
    Focus_on_commodities: '关注商品',
    sift: '筛选',
    all: '全部',
    unfollow: '取消关注',
    This_article_is_out_of_date: '此商品已过期',
    // 点开店铺关注按钮之后的页面
    Focus_on_the_store: '关注店铺',
    The_store_where_the_purchase_was_made: '购买过的店铺',
    // 点开浏览记录按钮之后的页面
    Records_management: '管理记录',
    Funding_account: '资金账户',
    Available_balance: '可用余额',
    Frozen_balance: '冻结余额',
    Withdrawals: '提现',
    recharge: '充值',
    Funding_details: '资金明细',
    Enquiry_Date: '查询日期',
    reach: '至',
    Payment_batch_number: '付款批次号',
    product_name: '产品名称',
    amount: '金额',
    Pay_the_order_number: '支付订单号',
    returned: '已退还',
    thaw: '解冻',
    freeze: '冻结',
    recharge_amount: '充值金额',
    Withdrawal_amount: '提现金额',
    Confirm_recharge: '确认充值',
    Confirm_Withdrawal: '确认提现',
    Open_a_funding_account: '开通资金账户',
    unreviewed: '未审核',
    has_been_opened: '已开通',
    To_be_verified: '待验证',
    Paid_for_review: '已打款待审核',
    be_rejected: '被驳回',
    unknown_state: '未知状态',
    A_personal_account: '个人账户',
    Enterprise_legal_person_account: '企业（法人）账户',
    Verify_fund_account: '验证资金账户',
    ID_Number: '身份证号',
    Funding_Password: '资金密码',
    // 未开通资金账户
    The_fund_account_has_not_yet_been_opened: '资金账户暂未开通，请补充资料发起开通申请',
    Account_Classification: '账户分类',
    Enterprise_opening: '企业开通',
    company_information: '企业信息',
    Company_Name: '企业名称',
    Company_Address: '企业地址',
    Business_Period: '营业期限',
    Whether_it_is_long_term: '是否为长期',
    be: '是',
    not: '否',
    start_date: '开始日期',
    date_closed: '结束日期',
    Social_Unified_Authentication_Code: '社会统一认证代码',
    Organization_Code: '组织机构代码',
    Organization_Certificate_Validity_Period: '组织机构证书有效期',
    Name_of_legal_representative: '法人姓名',
    Corporate_Identity_Card: '法人身份证',
    ID_Card_Validity_Period: '身份证有效期',
    Basic_Account_Number: '基本户账号',
    Bank_Associate_Number: '开户银行联行号',
    Please_enter_the_bank_name: '请输入银行名称',
    Confirm_Funds_Password: '确认资金密码',
    Upload_ID_card: '上传身份证',
    Upload_enterprise_information: '上传企业信息',
    Upload_authorization_certificate: '上传授权证书',
    Upload_your_business_license: '上传营业执照',
    Id_card_face: '身份证人面像',
    National_identity_card_emblem: '身份证国徽像',
    Hold_an_ID_card: '手持身份证',
    Basic_account_of_the_enterprise: '企业基本户',
    EXP2C_Fund_account_service_agreement: 'e贸网资金账户服务协议',
    Submit_for_review: '提交审核',
    Bind_a_bank_card: '绑定银行卡',
    Bank_card_type: '银行卡类型',
    Enterprise_card_binding: '企业绑卡',
    Supervisory_accounts: '监管账户',
    Supervisory_account: '监管账号',
    fund_account: '会员资金账户',
    Opening_city: '开户城市',
    Enterprise_Name: '企业名称',
    Please_enter_enterprise_name: '请输入企业名称',
    ID_type: '证件类型',
    BANK_ID_Number: '证件号码',
    Please_enter_ID_number: '请输入证件号码',
    phone_number: '手机号码',
    Please_enter_mobile_phone_number: '请输入手机号码',
    Bank_card_number: '银行卡号',
    Please_enter_bank_card_number: '请输入银行卡号',
    Name_of_Account_Bank: '开户行名称',
    Please_enter_Account_Bank: '请输入开户行',
    bank_name: '银行名称',
    Commit_binding: '提交绑定',
    Commit_to_unbind: '提交解绑',
    Card_management: '银行卡管理',
    Add_bank_card: '添加银行卡',
    in_review: '审核中',
    To_be_backfilled: '待回填',
    remove_binding: '解绑',
    backfill: '回填',
    Backfill_amount: '回填金额',
    delete: '删除',
    unbind: '取消绑定',
    My_Orders: '我的订单',
    All_Orders: '全部订单',
    Pending_payments: '待付款',
    Wait_for_early_delivery: '待预提',
    Goods_to_be_confirmed: '待确认备货',
    wait_for_receiving: '待收货',
    finished: '已完成',
    commodity_information: '商品信息',
    quantity: '数量',
    consignee: '收货人',
    state: '状态',
    operate: '操作',
    order_number: '订单号',
    specification: '规格',
    delivery_date: '交货日期',
    Barter_date: '易货日期',
    None: '暂无',
    logistics: '物流',
    Order_details: '订单详情',
    payment: '付款',
    Cancel_Order: '取消订单',
    order_status: '订单状态',
    Contact_seller: '联系卖家',
    logistics_information: '物流信息',
    order_information: '订单信息',
    order_detail_number: '订单编号',
    Delivery_date: '商品交付日',
    creation_time: '创建时间',
    Seller_information: '卖家信息',
    seller_name: '卖家名称',
    phone: '联系电话',
    invoice_information: '发票信息',
    commodity_price: '商品总价',
    accrued_expenses: '预提费用',
    logistics_fees: '物流费',
    Stock_up_expenses: '备货费用',
    Total_amount: '商品总额',
    actual_payment: '实付款',
    leave_words: '留言',
    detail_product_name: '商品名称',
    unit_price: '单价',
    Accrued_expenses: '预提费用',
    shipping_address: '收货地址',
    Add_a_shipping_address: '添加收货地址',
    New_shipping_address: '新增收货地址',
    consignee_name: '收货人姓名',
    Please_enter_consignee_name: '请输入收货人姓名',
    detailed_address: '详细地址',
    Please_enter_detailed_address: '请输入详细地址',
    Is_it_set_to_default_address: '是否设置为默认地址？',
    Save_and_use: '保存并使用',
    name: '姓名',
    tacitly_approve: '默认',
    redact: '编辑',
    Invoice_management: '发票管理',
    Add_an_invoice: '添加发票',
    Pack_up_all: '收起全部',
    Full_expansion: '全部展开',
    Security_settings: '安全设置',
    Modify_mobile_phone_number: '修改手机号',
    Login_password: '登陆密码',
    Changing_the_login_Password: '修改登录密码',
    payment_password: '支付密码',
    Change_payment_password: '修改支付密码'
  },
  merchantworkbench: {
    Release_pre_sale: '发布预售',
    Release_from_stock: '发布现货',
    Publish_auction: '发布竞拍',
    Release_Barter: '发布易货',
    back_homepage: '返回首页',
    Release_management: '发布管理',
    Basic_information: '基本信息',
    please_choose: '请选择',
    Unit_of_measurement: '计量单位',
    producer: '产地',
    Specification_settings: '规格设置',
    Delivery_date: '交货日期',
    Sale_price: '销售价格',
    Property_picture: '属性图片',
    Rule_settings: '规则设置',
    Expiry_date_of_offer: '报价有效期',
    option_date: '选择日期',
    Currency: '币种',
    RMB: '人民币',
    USD: '美元',
    online_payment: '在线支付',
    Permissible_overflow_ratio: '允许溢折比例',
    Maximum_10: '最大不超过10%',
    Minimum_increment: '最小增量',
    Unit: '单位',
    upload: '点击上传',
    replace: '点击更换',
    Add: '点击添加',
    Added_a_withholding_method: '新增预提方式',
    Please_enter_specification: '请输入规格',
    Please_enter_price: '请输入价格',
    The_specification: '规格是包装规格/运输规格，价钱是包装价钱/运输价钱',
    Resale_allowed: '是否允许转售',
    Resale_is_not_allowed: '不允许转售',
    Number_of_days_in_advance: '预提提前天数',
    Settlement_warehouse: '交收仓库',
    Coffee_ettings: '咖啡设置',
    Coffee_Estate: '咖啡庄园',
    Manor_introduction: '庄园介绍',
    grade: '等级',
    authentication: '认证',
    cuppingReference: '杯测参考',
    treeSpecies: '树种',
    Planting_altitude: '种植海拔',
    metre: '米',
    Year_of_harvest: '采摘年份',
    processing_method: '加工方法',
    Triple_anaerobic: '三重厌氧',
    Double_fermentation_in_the_sun: '双重发酵日晒',
    Kombucha_hair_western_sun_exposure: '康普茶发西都日晒',
    solarization: '日晒',
    Aerobic_fermentation_in_the_sun: '有氧发酵日晒',
    Washing: '水洗',
    Wash_with: '水洗96小时发酵',
    wet_dig: '湿刨',
    wet_dig_handle: '湿刨处理',
    dense_handle: '密处理',
    dense_handle_Late_Receipt_Batch: '密处理(晚收批次)',
    other: '其他',
    Flavor_description: '风味描述',
    words: '1-200字',
    Please_enter_a_flavor_description: '请输入风味描述',
    Main_product_image: '商品主图',
    Upload_up: '最多上传5张图片，点击更换图片',
    Supports_JPG: '支持jpg、jpeg、png、gif格式',
    Product_detail_page: '商品详情页',
    Note_Please: '注意：请认真排版，向客户展示时，将严格按此排版展示',
    submit: '提交',
    Freight: '运费',
    domestic: '国内',
    foreign: '国外',
    Settlement_area: '交收区域',
    Please_select: '请选择交收区域',
    Choose_spot_or_pre_sale: '选择现货或预售',
    Pre_sale_goods: '预售商品',
    Please_select_a: '请选择商品名称',
    Delivery_time: '交货时间',
    Bidding_rules: '竞价规则',
    Auction_title: '竞价标题',
    please_enter: '请输入',
    please_fill_in: '请填写',
    Total_number_of_bids: '竞拍总量',
    Starting_unit_price: '起拍单价',
    Minimum_markup_unit: '最小加价单位',
    Minimum_transaction_ratio: '最低成交比',
    Markdown_cycle: '降价周期',
    lowest_price: '最低价格',
    content: '内容',
    detailed_introduction: '详情介绍',
    earnest_money_ratio: '定金',
    Auction_period: '竞拍时段',
    publish: '发布',
    Product_Inventory: '商品库存',
    Effective_Date: '有效日期',
    Deposit: '保证金',
    Pay_deposit: '缴纳保证金',
    Goods_to_be_prepared: '待备货',
    Goods_to_be_shipped: '待发货',
    shipped: '已发货',
    Deal_Close: '交易完成',
    Canceled: '已取消',
    buyer: '买家名称',
    Transaction_Status: '交易状态',
    See_details: '查看详情',
    order_management: '订单管理',
    Order_details: '订单详情',
    User_ID: '用户ID',
    Shipping_Method: '配送方式',
    None: '暂无',
    Contract: '合同',
    Documents: '单据',
    Buyer_message: '买家留言',
    Fee_information: '费用信息',
    Transferred_quantity: '已转数量',
    Stock_Difference: '备货差价',
    Logistics_fee: '物流费用',
    accounts_payable: '应付',
    Paid: '实付',
    Merchandise_management: '商品管理',
    On_the_shelf: '已上架',
    Removed_from_shelves: '已下架',
    merchandise_categories: '商品类别',
    hit_the_shelf: '上架',
    out_of_stock: '下架',
    Audit_management: '审核管理',
    pass_the_audit: '审核通过',
    unreviewed: '未审核',
    not_pass: '未通过',
    Withholding_to_be_confirmedL: '待确认预提',
    contact_information: '联系方式',
    Stock_quantity: '备货数量',
    Republish: '重新发布',
    Product_ID: '商品编号',
    release_time: '发布时间'

  },
  special: {
    Zone_homepage: '专区首页',
    Resale_hall: '转售大厅',
    Auction_hall: '竞拍大厅',
    Barter_hall: '易货大厅',
    Standard_price_action: '标准价格走势',
    Please_select_a_variety: '请选择品种',
    The_transaction_price: '成交价格',
    region: '地区',
    Zone_recommendation: '专区推荐',
    rush_to_purchase: '抢购'
  }
}
