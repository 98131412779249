/* eslint-disable eqeqeq */
// import store from './store.js'
import { createStore } from 'vuex'
import { getStore, setStore } from '../tools/storage'
import { setStoreChatList } from '@/common/chatStore.js'
import {
  getcustomermessagelist
} from '../common/InForMessage.js'

export default createStore({
  state: {
    mapCity: [], // 地图上城市仓库的名字
    login: false, // 是否登录
    userInfo: null, // 用户信息
    citysId: '', // 存储当前城市id
    personalNumber: '', // 存储当前买家后台选中的页面index
    // merchantNumber: '', // 存储当前卖家后台选中的页面index

    currentid: '', // 当前聊天的房间号
    alllist: [], // 买家消息列表全部
    separatelist: [], // 买家消息列表单聊
    morelist: [], // 买家消息列表讨论组
    allSpeakUnread: 0, // 所有会话未读数
    sellerlist: [], // 卖家单聊列表
    sellermore: [] // 卖家群聊
  },
  mutations: {
    // 设置城市
    setMapData (state, newValue) {
      state.mapCity.push(newValue)
    },
    setLogin (state, newValue) {
      state.login = true
      state.userInfo = newValue
    },

    // 消息模块
    setConfirmOrder (state, data) {
      state.confirmData = data
      // state.confirmData.skuId = data.skuId;
      // state.confirmData.number = data.number;
      // state.confirmData.skuCode = data.skuCode;
      // state.confirmData.group = data.group;
      // state.confirmData.groupReductionId = data.groupReductionId;
    },

    // 存入当前聊天窗口的房间id
    currentRoomid (state, id) {
      this.state.currentid = id
      // console.log(this.state.currentid)
    },

    // 删除当前聊天窗口的房间id
    deleteRoomid (state) {
      this.state.currentid = ''
      console.log(this.state.currentid)
    },

    // 处理接受的消息不是当前会话窗口
    solveMessage (state, mes) {
      const message = JSON.parse(mes.data)
      console.log(message)
      const merchantStype = setStore('merchants-type')
      console.log(merchantStype)
      if (message.code === 2 && merchantStype === '2') {
        if (this.state.currentid !== message.message.id) {
          // 不是当前会话窗口
          console.log('不是当前会话窗口')
          this.commit('solveUnread', message.message)
        } else if (this.state.currentid == message.message.id) {
          // 是当前会话窗口
          console.log('是当前会话窗口')
          this.commit('solveUnreads', message.message)
        }
      } else if (message.code == 2 && merchantStype == '1') {
        console.log('卖家')
        if (this.state.currentid != message.message.id) {
          // 不是当前会话窗口
          this.commit('solveUnreadSeller', message.message)
        } else if (this.state.currentid == message.message.id) {
          console.log('卖家端是当前会话窗口')
        }
      }
    },

    // 处理卖家不在当前会话时
    solveUnreadSeller (state, mes) {
      console.log(mes)
      const unreadId = mes.id// 接受信息读房间id
      if (mes.type == '0') {
        // 单聊
        const sellerlist = getStore('sellerlist') || []
        let unreadCounts = Number(getStore('unreadCounts'))
        let s = null
        if (sellerlist && sellerlist.length > 0) {
          s = sellerlist.findIndex((items) => {
            console.log(items.id)
            return items.id == unreadId
          })
        } else {
          s = -1
        }
        console.log(s)
        if (s < 0) {
          // 不重复
          const merchantIds = getStore('phuse').merchantId || ''
          const solveMes = {
            avatar: mes.avatar,
            buyerAvatar: mes.avatar,
            buyerName: mes.username,
            merchantId: merchantIds,
            chatType: '0',
            id: mes.id,
            show: false,
            remarks: mes.content,
            unreadCount: 1,
            updateDate: mes.timestamp
          }
          sellerlist.unshift(solveMes)
          this.state.sellerlist = sellerlist
          unreadCounts = unreadCounts + 1
          this.state.unreadCounts = unreadCounts
          setStore('sellerlist', sellerlist)
          setStore('unreadCounts', unreadCounts)
        } else {
          // 重复
          sellerlist[s].unreadCount = Number(sellerlist[s].unreadCount) + 1
          console.log(Number(sellerlist[s].unreadCount) + 1)
          unreadCounts = unreadCounts + 1
          console.log(unreadCounts)
          sellerlist[s].remarks = mes.content
          state.sellerlist = sellerlist
          state.unreadCounts = unreadCounts
          setStore('sellerlist', sellerlist)
          setStore('unreadCounts', unreadCounts)
          console.log(sellerlist)
        }
      }
    },

    // 获取买家消息列表数据
    async getBuyersList (state) {
      const data = {
        pageNum: 1,
        pageSize: 500,
        readStatus: 2,
        role: 0// 0买家
      }
      const res = await getcustomermessagelist(data)
      console.log(res)
      const result = res.data.data
      setStore('BuyersList', result)
      // setStoreChatList('BuyersList',result)
      console.log(getStore('BuyersList'))
      // this.state.alllist = result.allSpeakList.records || []
      const allSpeakUnread = Number(result.singleSpeakUnread) + Number(result.groupSpeakUnread)
      this.state.allSpeakUnread = allSpeakUnread// 总未读数
      setStoreChatList('allSpeakUnread', allSpeakUnread)
      this.state.morelist = result.groupSpeakList.records || []// 讨论组
      this.state.separatelist = result.singleSpeakList.records || []// 单聊
    },

    // 处理接受不在同一聊天会话的未读信息
    solveUnread (state, mes) {
      console.log('处理同一聊天会话同步到买家会话列表')
      console.log(mes)
      const unreadId = mes.id// 接受信息读房间id

      if (mes.type == 0 && mes.type) {
        console.log('ppppp8888888pppppp')
        // 单聊
        // 判断是否重复,重复返回下标值，不重复返回-1
        console.log(unreadId)
        const alllist = getStore('BuyersList')// 所有的买家列表信息
        console.log(alllist)
        console.log(alllist.singleSpeakList.records)
        const lists = alllist.singleSpeakList.records
        console.log(lists)
        const s = lists.findIndex((items) => {
          console.log(items.id)
          return items.id == unreadId
        })
        console.log(alllist)
        console.log(s)
        if (s == -1) {
          // 没有
          const solveMes = {
            sellerAvatar: mes.avatar,
            sellerName: mes.username,
            chatType: '0',
            id: mes.id,
            show: false,
            remarks: mes.content,
            unreadCount: 1,
            updateDate: mes.timestamp
          }

          this.state.separatelist.unshift(solveMes)
          console.log(getStore('BuyersList'))
          alllist.singleSpeakList.records = this.state.separatelist
          console.log(this.state.separatelist)
          console.log(alllist.singleSpeakList.records)
          console.log(alllist)
          this.state.allSpeakUnread++
          setStoreChatList('allSpeakUnread', this.state.allSpeakUnread)
          setStore('BuyersList', alllist)
          // setStoreChatList('BuyersList',alllist)
          console.log(getStore('BuyersList'))
        } else {
          // 有
          console.log(s)
          console.log(this.state.separatelist)
          this.state.separatelist[s].unreadCount = this.state.separatelist[s].unreadCount + 1
          this.state.separatelist[s].updateDate = mes.timestamp
          this.state.allSpeakUnread++
          this.state.separatelist[s].remarks = mes.content
          console.log(this.state.separatelist[s].unreadCount)
          console.log(this.state.separatelist[s].unreadCount + 1)
          alllist.singleSpeakList.records = this.state.separatelist
          setStoreChatList('allSpeakUnread', this.state.allSpeakUnread)
          setStore('BuyersList', alllist)
          console.log(this.state.separatelist)
        }
      } else if (mes.type == 1 && mes.type) {
        // 群聊讨论组

      }
    },

    // 处理同一聊天会话同步到买家会话列表
    solveUnreads (state, mes) {
      console.log('处理同一聊天会话同步到买家会话列表')
      console.log(mes)
      const unreadId = mes.id// 接受信息读房间id
      console.log(unreadId)

      if (mes.type == 0 && mes.type) {
        console.log('ppppppppppp')

        // 单聊
        // 判断是否重复,重复返回下标值，不重复返回-1
        const alllist = getStore('BuyersList')// 所有的买家列表信息
        console.log(alllist)
        const lists = alllist.singleSpeakList.records
        console.log(lists)
        const s = lists.findIndex((items) => {
          console.log(items.id)
          return items.id == unreadId
        })
        console.log(s)
        if (s == -1) {
          // 没有
          console.log('没有--')
          const solveMes = {
            sellerAvatar: mes.avatar,
            sellerName: mes.username,
            chatType: '0',
            id: mes.id,
            remarks: mes.content,
            unreadCount: 0,
            updateDate: mes.timestamp
          }
          this.state.separatelist.unshift(solveMes)
          console.log(this.state.separatelist)
          alllist.singleSpeakList.records = this.state.separatelist
          console.log(alllist.singleSpeakList.records)
          console.log(alllist)
          setStore('BuyersList', alllist)

          console.log(getStore('BuyersList'))
        } else {
          // 有
          console.log('有--')
          console.log(s)
          console.log(this.state.separatelist)
          this.state.separatelist[s].updateDate = mes.timestamp
          this.state.separatelist[s].remarks = mes.content
          alllist.singleSpeakList.records = this.state.separatelist
          setStore('BuyersList', alllist)
          console.log(this.state.separatelist)
        }
      } else if (mes.type === 1 && mes.type) {
        // 群聊讨论组
      }
    }
  },
  actions: {
    // 设置城市
    getMapData (state, newValue) {
      state.mapCity.push(newValue)
    }
  },
  modules: {
  }
})
