// const baseurls = 'http://test.exp2c.com/' // 测试本地
const baseurls = 'http://www.exp2c.com/' // 正式

// const url = 'https://www.exp2c.com/api'// 正式

// const url = 'http://47.92.69.121:8201'// 测试ip
const url = 'https://test.exp2c.com/api'// 测试

// const url = 'http://192.168.110.134:8201' // 游德香
// const url = 'http://192.168.110.59:8201' // 姜锐林

const wsport = '/ws/'
const wsduankou = '/kf/'
// const wsscheme = 'wss://' // 服务器
const wsscheme = 'ws://' // 本地
// const wsurl = '192.168.110.129:9326'// 测试本地
// const wsurl = 'exp2c.com'
const wsurl = '47.92.69.121:9326'
// const wsurl = 'www.exp2c.com'

if (process.env.NODE_ENV === 'development') {
  console.log('========= 开发环境 =========')
  //  Url = 'https://api.buyp2c.com'
  // baseurls = 'https://exp2c.com'
  // //  wsscheme = 'wss://'
  // wsport = '/wss'
  // wsurl = 'www.exp2c.com'
} else {
  console.log('========= 生产环境 =========')
  // url = 'https://api.exp2c.com'
  // baseurls = 'https://exp2c.com'
  // // wsscheme = 'wss://'
  // wsport = '/wss'
  // wsurl = 'exp2c.com'
}

export { url, baseurls, wsscheme, wsport, wsduankou, wsurl }
