export const m = {
  Header: {
    coffe_community: 'Coffee community',
    Switch_merchant_side: 'Switch merchant side',
    APP_Download: 'APP Download',
    Official_WeChat: 'Official WeChat',
    customer_service: 'Customer service',
    home_page: 'Home',
    mobile_terminal: 'Mobile terminal',
    login_again: 'login again'
  },
  index: {
    quick_login: 'Quick login',
    Login_to_view_prices: 'Login to prices',
    Port_of_Kunshan: 'Port of Kunshan',
    New_York_Futures: 'New York Futures',
    Jumbo_Futures: 'Jumbo Futures',
    Singapore_Commodities: 'Singapore Commodities',
    London_futures: 'London Futures',
    product_name: 'Product name',
    transaction_price: 'Transaction price',
    turnover: 'Turnover',
    region: 'Region',
    Transaction_time: 'Transaction time',
    No_transaction_dynamics: 'No transaction dynamics!',
    Quick_buy: 'Quick buy',
    Estate_presale: 'Estate presale',
    Next_estate: 'Next estate',
    Pre_sale_name: 'Presale name',
    price: 'Price',
    capacity: 'Capacity',
    Founders_Day: 'Founders Day',
    reserve: 'Reserve',
    information_center: 'Information center',
    more: 'more',
    No_information_available: 'No information available',
    ranking_list: 'Ranking list',
    Variety_transaction_volume: 'Variety transaction volume',
    Heat_Ranking: 'Heat ranking',
    Latest_launch: 'Latest launch',
    ranking: 'ranking',
    variety: 'variety',
    Transaction_volume: 'Transaction volume',
    inventory: 'inventory',
    No_transaction: 'No transaction',
    today: 'Today',
    this_week: 'this week',
    this_month: 'this month',
    accumulate: 'accumulate',
    Global_high_supplier_recommendation: 'Global high-quality supplier recommendation',
    cooperating_suppliers_with_categories: '7+cooperating suppliers * with complete categories',
    Live_broadcast_hall: 'Live broadcast hall',
    no_live_information_available: 'There is currently no live streaming information available',
    Presale_hall: 'Presale hall',
    spot_market: 'Spot market hall',
    Interactive_warehouse: 'Interactive warehouse',
    logistics_service: 'Logistics service',
    sales_volume: 'sales volume',
    expiration_date: 'expiration date',
    no_presale_information_available: 'There is currently no pre-sale information available',
    no_data_spot_commodity: 'There is currently no spot commodity',
    Enter_auction_hall: 'Enter the auction hall',
    trade_name: 'Trade name',
    Latest_price: 'Latest price',
    name: 'name',
    starting_price: 'starting price',
    yuan: 'Yuan',
    Delivery_Date: 'Delivery Date',
    Minimum_markup: 'Minimum markup',
    bidding: 'Bidding',
    start_time: 'start time',
    Barter_hall: 'Barter hall',
    existing: 'existing',
    target: 'target',
    no_barter_information_available: 'There is currently no barter information available',
    company_address: 'Company address',
    company_address_content: 'No. 1, 356 East Xinnan Road, Kunshan Development Zone (Comprehensive protection Zone business building 2)',
    fixed_line_telephone: 'fixed-line telephone',
    ccdt: 'CCDT',
    All_right_reserved: 'All right reserved',
    Business_license: 'Business license'

  },
  search: {
    product: 'Product',
    supplier: 'Supplier',
    search: 'Search',
    commodity: 'commodity',
    Today_Headlines: "Today's Headlines",
    Capacity_presale: 'Capacity presale',
    Spot_listing: 'Spot listing',
    barter: 'Barter',
    region: 'Region',
    bidding: 'Bidding',
    live_streaming: 'live streaming',
    login: 'Login',
    transaction_rules: 'Trading rules',
    Instructions_for_membership: 'Instructions for membership',
    user_manual: 'User manual',
    log_off: 'Log off',
    user_center: 'User center'
  },
  loginandregister: {
    Account_login: 'Account login',
    username: 'username',
    password: 'password',
    Please_enter_username: 'Please enter phone number',
    Please_enter_password: 'Please enter password',
    Login_immediately: 'Login immediately',
    free_registration: 'free registration',
    forget_password: 'forget password',
    register_account: 'Register account',
    Company_Name: 'Company name',
    Please_enter_Company_Name: 'Please enter Company name',
    Contact: 'Contact',
    Please_enter_Contact: 'Please enter contact',
    phone_number: 'phone number',
    auth_code: 'auth code',
    Please_enter_phone_number: 'Please enter phone number',
    TestGetCode: 'TestGetCode',
    Please_enter_auth_code: 'Please enter auth code',
    second: 's',
    regain: 'regain',
    Please_code_again: 'Please obtain the verification code again',
    Set_Password: 'set Password',
    Please_set_let_num: 'Please set 8-16 letters + numbers',
    account_type: 'account type',
    buyer: 'Buyer',
    seller: 'Seller',
    Confirm_Password: 'confirm Password',
    Read_and_agree: 'Read and agree',
    User_registration_protocol: 'User registration protocol',
    privacy_policy: 'Privacy policy',
    Register_Now: 'Register Now',
    Registered_to_login: 'Registered to login',
    phonenumber_incorrect: 'The phone number is incorrect',
    Retrieve_password: 'Retrieve password',
    next_step: 'next step',
    reset_password: 'Reset password',
    confirm: 'Confirm',
    Two_passwords: 'Two different passwords'
  },
  reservation: {
    Presale_transaction_process: 'Presale transaction process',
    Transaction_dynamics: 'Transaction dynamics',
    product_name: 'Product name',
    time: 'Time',
    turnover: 'Turnover',
    reset: 'Reset',
    selected: 'Selected',
    variety: 'Rariety',
    Maragogype: 'Maragogype',
    Villa_sarchi: 'Villa sarchi',
    Pacas: 'Pacas',
    Caturra: 'Caturra',
    bourbon: 'bourbon',
    Typica: 'Typica',
    sales_volume: 'sales volume',
    Sales_in_descending_order: 'Sales in descending order',
    in_ascending_order_of_sales_volume: 'in ascending order of sales volume',
    Pre_sale: 'Presale',
    Deadline: 'Deadline',
    Price: 'Price',
    Price_in_descending_order: 'Price in descending order',
    Price_in_ascending_order: 'Price in ascending order',
    No_data_found: 'No data found'

  },
  cashcommodity: {
    Spot_transaction_process: 'Spot transaction process',
    spot_goods: 'Spot goods'
  },
  barter: {
    Barter_transaction_process: 'Barter transaction process',
    news: 'news',
    existing_products: 'existing products',
    Name: 'Name',
    inventory: 'inventory',
    intended_exchange_commodity: 'intended exchange commodity'
  },
  auction: {
    Bidding_process: 'Bidding process',
    My_auction: 'My auction',
    Auction_mode: 'Auction mode',
    English_Auction: 'Coffee auction',
    Fine_English_auction: 'Fine English Auction',
    Dutch_auction: 'Dutch Auction',
    all_products: 'all products',
    start_time: 'start time',
    end_time: 'end time',
    ton: 'ton',
    offers: 'offers',
    commodity_information: 'commodity information',
    quantity: 'quantity',
    amount: 'amount',
    state: 'state',
    operate: 'operate',
    My_bid: 'My bid',
    In_the_auction: 'In the auction',
    Successful_auction: 'Successful auction',
    Failed_auction: 'Failed auction'

  },
  live: {
    download: 'download',
    Go_live: 'Go live',
    help: 'help',
    report_an_offense: 'report an offense',
    Wonderful_live_streaming: 'Wonderful live streaming',
    hosts_are_live_streaming: 'hosts are live streaming',
    Much_more: 'Much more',
    No_live_streaming_information_available: 'No live streaming information available'
  },
  reservation_varietiy_details: {
    Price: 'Price:',
    Buyer_priority_to_pay: 'Buyer prioritizes payment',
    deposit: 'deposit',
    Stock: 'Stock:',
    Minimum_Order_Quantity: 'Minimum Order Quantity',
    Validity_of_Quotation: 'Validity of Quotation:',
    Minimum_Increment: 'Minimum Increment',
    Advanced_days_of_delivery: 'Advanced days of delivery',
    day: 'day',
    Resell_allowed: 'Resell allowed',
    Resale_not_allowed: ' Resale not allowed',
    Permission_to_resell_before_advance_delivery: 'Permission to resell before advance delivery',
    Selective_specification: 'Selective specification:',
    guigeName: 'Name',
    guigeStock: 'Stock',
    guigePrice: 'Price',
    guigepickup_date: 'Pickup date',
    Date_of_Taking_Delivery: 'Date of Taking Delivery:',
    Delivery_width: '200px',
    Select_the_date_of_taking_delivery: 'Select the date of taking delivery',
    number: 'Number:',
    Estimated_price: 'Estimated price',
    est_select_specifications: 'Selective specification',
    Please_select_specifications: 'Please select specifications',
    specification: 'specification',
    delivery_day: 'delivery day',
    Purchased_weight: 'Purchased weight',
    Delivery_method: 'Delivery method',
    Select_withholding_mode: 'Select the withholding mode',
    Prepayment_ratio: 'Prepayment ratio',
    pick_up_goods_cost: 'pickup goods cost',
    advance_payment: 'advance payment',
    final_payment: 'final payment',
    Estimated_total_payment: 'Estimated total payment',
    attention: 'attention',
    attentionLeft: '100px',
    purchase: 'purchase',
    purchaseLeft: '20px',
    Supplier_file: 'Supplier file',
    followed: 'followed',
    inquiry: 'inquiry',
    Coffee_details: 'Coffee details',
    Product_number: 'Product number',
    variety: 'variety',
    farm: 'farm',
    place_of_origin: 'place of origin',
    treeSpecies: 'treeSpecies',
    year: 'year',
    elevation: 'elevation',
    Picking_method: 'Picking method',
    way_of_quoting: 'way of quoting',
    processing_method: 'processing method',
    Coffee_flavor: 'Coffee flavor',
    brand: 'brand',
    date_issued: 'date issued',
    storage_life: 'storage life',
    place_of_dispatch: 'place of dispatch',
    delivery_points: 'delivery points',
    commodity_details: 'Commodity details'
  },
  barter_detail: {
    Self_estimated_price: 'Self estimated price:',
    Delivery_width: '150px',
    period_of_validity: 'Period of validity:',
    place_of_delivery: 'Place of delivery:',
    Conversion_ratio: 'Conversion ratio:',
    Logistics_compensation: 'Logistics compensation:',
    handling_costs: 'handling costs:',
    Advance_deposit: 'Advance deposit',
    To_generate_an_order: 'purchase',
    Pseudo_easy_price: 'Pseudo-easy price',
    want_to_trade_inventory: 'want to trade inventory',
    Estimate_whether_it_will_be_able_to_trade: 'Predict traded',
    Predict_whether_the_goods_will_be_traded: 'Predict whether will be traded',
    You_can_barter_successfully: 'You can barter successfully',
    Possible_unsuccessful_barter: 'Possible unsuccessful barter'
  },
  auction_englishauction: {
    day: 'D',
    hour: 'h',
    minute: 'm',
    second: 's',
    Delivery_width: 'auto',
    Delivery_Quantity_width: 'auto',
    bidding_is_ready_to_begin: 'Waiting for bidding begin:',
    Bidding_in_progress: 'Bidding in progress:',
    Unsettled: 'Unsettled:',
    traded: 'Traded:',
    finished: 'finished',
    completed: 'completed',
    From_the_begin_there_only_one_left: 'The begin of the only one left',
    The_end_of_the_distance_is_only_left: 'The end of the distance is only left',
    product_name: 'product name',
    present_price: 'present price',
    Quantity_sold: 'Quantity',
    Deposit_required: 'Deposit required',
    bid: 'bid',
    Current_price: 'Current price',
    purchase_quantity: 'purchase quantity',
    Likelihood_of_snatched: 'auction',
    Bidding_rules: 'Bidding rules',
    Scan_code_to_download_APP: 'Scan code to download APP',
    price_cut: 'price cut',
    Minimum_markup_unit: 'Minimum markup unit',
    sec: 'Sec',
    Minimum_purchase_quantity: 'Minimum purchase quantity',
    Minimum_increase: 'Minimum increase',
    deferred_cycle: 'deferred cycle',
    If_the_auction_the__days: 'If the auction fails, the deposit will be returned to the payment account within 1-3 working days',
    If_user_bids_the_bidding_extended: 'If any user bids within the last {time}min, the bidding period will be extended',
    Bid_record: 'Bid record',
    Bidding_specification: 'Bidding specification',
    top_price: 'top price',
    price: 'Price',
    Time: 'Time',
    bidder: 'Bidder',
    Dutch_bidding_rules: 'Dutch bidding rules: Dutch bidding is to auction inventory (inventory auction). The price of the Dutch bidding will fall according to the price reduction of the background maintenance, down to the lowest end of the background maintenance. The order is generated when the Dutch auction is finished or when the auction is finished, depending on the volume ratio. The generated order will be generated according to the lowest successful bid, and the overpaid deposit will be refunded. If bidding fails and no order is generated, the deposit shall be returned.',
    English_bidding_rules: 'English_bidding_rules: The highest bidder takes. After the bid is successful, an order will be generated according to the transaction ratio after the British bidding, and the generated order will be generated according to the lowest successful bid, and the overpaid deposit will be refunded. British bid fails (no order is generated), refund deposit.'

  },
  map: {
    Click_on_the_city_to_view_the_goods_under_the_city: 'Click on the city to view the goods under the city',
    Quick_trade: 'Quick trade',
    stock: 'stock',
    name: 'name',
    price: 'price',
    quantity: 'quantity',
    amount_of_increase: 'amount of increase',
    operation: 'operation',
    buy: 'buy',
    sale: 'sale',
    variety: 'variety',
    Presale_information: 'Presale information',
    presell: 'presell',
    resell: 'resell',
    merchant: 'merchant',
    warehouse: 'warehouse',
    load_more: 'load more',
    buying_price: 'buying price',
    Quantity_in_transit: 'resell Quantity',
    Sellable_quantity: 'Sellable quantity',
    Maximum_available: 'Maximum available',
    aggregate_amount: 'aggregate amount',
    Deposit_payable: 'Deposit payable',
    I_have_read_and_agree: 'I have read and agree',
    purchases_and_sales_contract: 'purchases and sales contract',
    trade_rules: 'trade rules',
    quality_standard: 'quality standard',
    cancel: 'cancel',
    confirm: 'confirm',
    Transferred_out: 'Transferred out',
    Selling_price: 'Selling price',
    Resale_expiration_date: 'Resale expiration date'

  },
  buy: {
    Confirm_receipt_information: 'Confirm receipt information',
    commodity_information: 'commodity information',
    commercial_specification: 'commercial specification',
    unit_price: 'unit price',
    quantity: 'quantity',
    sample_stock: 'sample stock',
    sample_quantity: 'sample quantity',
    total_prices: 'total prices',
    Advance_the_delivery_date: 'Advance the delivery date',
    Delivery_type: 'Delivery type',
    invoice_information: 'invoice information',
    Invoice_type: 'Invoice type',
    invoice_title: 'invoice title',
    check_taker: 'check-taker',
    Ticket_collection_telephone: 'Ticket collection telephone',
    Receipt_address: 'Receipt address',
    unfold: 'unfold',
    put_away: 'put away',
    View_all: 'View all',
    Tariff_Item: 'Tariff Item',
    Additional_invoice: 'Additional invoice',
    amend_an_invoice: 'amend an invoice',
    Deletion_of_invoice: 'Deletion of invoice',
    plain_invoice: 'plain invoice',
    vat_special_invoice: 'vat special invoice',
    Please_enter_the_invoice_title: 'Please enter the invoice title',
    taxpayer_identification_number: 'taxpayer identification number',
    Please_enter_taxpayer_identification_number: 'Please enter taxpayer identification number',
    Please_enter_check_taker: 'Please enter check taker',
    Please_enter_Ticket_collection_telephone: 'Please enter Ticket collection telephone',
    Please_enter_Receipt_address: 'Please enter Receipt address',
    Set_as_the_default_invoice: 'Set as the default invoice (Reminder: this invoice will be recommended by default for each order)',
    Save_and_Use_plain: 'Save and Use (plain)',
    deposit_bank: 'deposit bank',
    Please_enter_deposit_bank: 'Please enter deposit bank',
    account_number: 'account number',
    Please_enter_account_number: 'Please_enter_account_number',
    Account_phone_number: 'Account phone number',
    Please_enter_Account_phone_number: 'Please enter Account phone number',
    business_address: 'business address',
    Please_enter_business_address: 'Please enter business address',
    Save_and_Use: 'Save and Use',
    Please_make_sure_it_is_before_the_delivery_date: 'Please make sure it is before the delivery date',
    days_in_advance_of_delivery: 'days in advance of delivery',
    aggregate: 'aggregate',
    Total_amount_payable: 'Total amount payable',
    Finance_purchase: 'Finance purchase',
    outright_purchase: 'outright purchase',
    Type_of_delivery: 'Type of delivery',
    logistics: 'logistics',
    Take_their: 'Take their',
    Delivery_only: 'Delivery only',
    place_of_delivery: 'place of delivery',
    Domestic_distribution_only: 'Domestic distribution only',
    shipping_address: 'shipping address',
    modification: 'modification',
    delete: 'delete',
    add_shipping_address: 'add shipping address',
    Name_of_the_consignee: 'Name of the consignee',
    Please_enter_Name_of_the_consignee: 'Please enter Name of the consignee',
    Mobile_phone_number: 'Mobile phone number',
    Please_enter_Mobile_phone_number: 'Please enter Mobile phone number',
    Full_address: 'Full address',
    Please_enter_Full_address: 'Please enter Full address',
    Are_you_sure_you_want_to_delete_this_address: 'Are you sure you want to delete this address?'
  },
  buy_settleaccounts: {
    Please: 'Please you',
    Please_complete: 'Complete the payment within, otherwise the order will be automatically cancelled',
    Amount_due: 'Amount due',
    Refundable_amount: 'Refundable amount',
    Payment_Methods: 'Payment Methods',
    Balance_payment: 'Balance payment',
    Payment_password: 'Payment password',
    Please_enter_a_6_digit_payment_PIN: 'Please enter a 6-digit payment PIN',
    pay: 'pay',
    You_have_paid_successfully: 'You have paid successfully'

  },
  paySuccess: {
    Your_payment_has_been_successful: 'Your payment has been successful',
    Actual_payment: 'Actual payment',
    view_orde_details: 'view orde details'
  },
  personalcenter_myorder: {
    ctleftwidth: 'min-width:202.62px;',
    personal_data: 'Personal data',
    Personal_Information: 'Personal Information',
    Personal_Information_Details: 'Personal Information Details',
    My_concern: 'My concern',
    Product_fucos: 'Product fucos',
    Store_attention: 'Store attention',
    Browsing_history: 'Browsing history',
    User_Name: 'User Name',
    Mobile_Number: 'Mobile Number',
    E_mail: 'E-mail',
    amend: 'amend',
    bind: 'bind',
    confirm: 'confirm',
    change_information: 'Change information',
    Created_in: 'Created in',
    // 点开商品关注按钮之后的页面
    Focus_on_commodities: 'Focus on commodities',
    sift: 'sift',
    all: 'all',
    unfollow: 'unfollow',
    This_article_is_out_of_date: 'This article is out of date',
    // 点开店铺关注按钮之后的页面
    Focus_on_the_store: 'Focus on the store',
    The_store_where_the_purchase_was_made: 'The store where the purchase was made',
    // 点开浏览记录按钮之后的页面
    Records_management: 'Records management',
    Funding_account: 'Funding account',
    Available_balance: 'Available balance',
    Frozen_balance: 'Frozen balance',
    Withdrawals: 'Withdrawals',
    recharge: 'Recharge',
    Funding_details: 'Funding details',
    Enquiry_Date: 'Enquiry Date',
    reach: 'reach',
    Payment_batch_number: 'Payment batch number',
    product_name: 'Product name',
    amount: 'amount',
    Pay_the_order_number: 'Pay order number',
    returned: 'Returned',
    thaw: 'Thaw',
    freeze: 'Freeze',
    recharge_amount: 'Recharge amount',
    Withdrawal_amount: 'Withdrawal amount',
    Confirm_recharge: 'Confirm recharge',
    Confirm_Withdrawal: 'Confirm withdrawal',
    Open_a_funding_account: 'Open a funding account',
    // 已开通资金账户
    unreviewed: 'Unreviewed',
    has_been_opened: 'Has been opened',
    To_be_verified: 'To be verified',
    Paid_for_review: 'Paid for review',
    be_rejected: 'Be rejected',
    unknown_state: 'Unknown state',
    A_personal_account: 'A personal account',
    Enterprise_legal_person_account: 'Enterprise (legal person) account',
    Verify_fund_account: 'Verify fund account',
    ID_Number: 'ID Number',
    Funding_Password: 'Funding Password',
    // 未开通资金账户
    The_fund_account_has_not_yet_been_opened: 'The fund account has not yet been opened, please add information to initiate the opening application',
    Account_Classification: 'Account Classification',
    Enterprise_opening: 'Enterprise opening',
    company_information: 'Company information',
    Company_Name: 'Company Name',
    Company_Address: 'Company Address',
    Business_Period: 'Business Period',
    Whether_it_is_long_term: 'Whether it is long-term',
    be: 'Yes',
    not: 'No',
    start_date: 'start date',
    date_closed: 'date closed',
    Social_Unified_Authentication_Code: 'Social Unified Authentication Code',
    Organization_Code: 'Organization Code',
    Organization_Certificate_Validity_Period: 'Organization Certificate Validity Period',
    Name_of_legal_representative: 'Legal Person name',
    Corporate_Identity_Card: 'Corporate Identity Card',
    ID_Card_Validity_Period: 'ID Card Validity Period',
    Basic_Account_Number: 'Basic Account Number',
    Bank_Associate_Number: 'Bank Associate Number',
    Please_enter_the_bank_name: 'Please enter the bank name',
    Confirm_Funds_Password: 'Confirm Funds Password',
    Upload_ID_card: 'Upload ID card',
    Upload_enterprise_information: 'Upload enterprise information',
    Upload_authorization_certificate: 'Upload authorization certificate',
    Upload_your_business_license: 'Upload your business license',
    Id_card_face: 'Id card face',
    National_identity_card_emblem: 'National identity card emblem',
    Hold_an_ID_card: 'Hold an ID card',
    Basic_account_of_the_enterprise: 'Basic account of the enterprise',
    EXP2C_Fund_account_service_agreement: 'EXP2C Fund account service agreement',
    Submit_for_review: 'Submit for review',
    Bind_a_bank_card: 'Bind a bank card',
    Bank_card_type: 'Bank card type',
    Enterprise_card_binding: 'Enterprise card binding',
    Supervisory_accounts: 'Supervisory accounts',
    Supervisory_account: 'Supervisory account',
    fund_account: 'Fund account',
    Opening_city: 'Opening city',
    Enterprise_Name: 'Enterprise Name',
    Please_enter_enterprise_name: 'Please enter the enterprise name',
    ID_type: 'ID type',
    BANK_ID_Number: 'ID Number',
    Please_enter_ID_number: 'Please enter your ID number',
    phone_number: 'Phone number',
    Please_enter_mobile_phone_number: 'Please enter mobile phone number',
    Bank_card_number: 'Bank card number',
    Please_enter_bank_card_number: 'Please enter bank card number',
    Name_of_Account_Bank: 'Name of Account Bank',
    Please_enter_Account_Bank: 'Please enter account Bank',
    bank_name: 'Bank name',
    Commit_binding: 'Commit binding',
    Commit_to_unbind: 'Commit to unbind',
    Card_management: 'Card management',
    Add_bank_card: 'Add bank card',
    in_review: 'In review',
    To_be_backfilled: 'To be backfilled',
    remove_binding: 'remove binding',
    unbind: 'unbind',
    backfill: 'backfill',
    Backfill_amount: 'Backfill amount',
    delete: 'delete',
    My_Orders: 'My Orders',
    All_Orders: 'All Orders',
    Pending_payments: 'Pending payments',
    Wait_for_early_delivery: 'Wait for early delivery',
    Goods_to_be_confirmed: 'Goods to be confirmed',
    wait_for_receiving: 'wait for receiving',
    finished: 'finished',
    commodity_information: 'commodity information',
    quantity: 'Quantity',
    consignee: 'consignee',
    state: 'state',
    operate: 'operate',
    order_number: 'order number',
    specification: 'specification',
    delivery_date: 'delivery date',
    Barter_date: 'Barter date',
    None: 'None',
    logistics: 'logistics',
    Order_details: 'Order details',
    payment: 'payment',
    Cancel_Order: 'Cancel Order',
    order_status: 'order status',
    Contact_seller: 'Contact seller',
    logistics_information: 'logistics information',
    order_information: 'order information',
    order_detail_number: 'order number',
    Delivery_date: 'Delivery date',
    creation_time: 'creation time',
    Seller_information: 'Seller information',
    seller_name: 'seller name',
    phone: 'phone',
    invoice_information: 'invoice information',
    commodity_price: 'commodity price',
    accrued_expenses: 'accrued expenses',
    logistics_fees: 'logistics fees',
    Stock_up_expenses: 'Stock up expenses',
    Total_amount: 'Total amount',
    actual_payment: 'actual payment',
    leave_words: 'leave words',
    detail_product_name: 'product name',
    unit_price: 'unit price',
    Accrued_expenses: 'Accrued expenses',
    shipping_address: 'Shipping address',
    Add_a_shipping_address: 'Add a shipping address',
    New_shipping_address: 'New shipping address',
    consignee_name: 'consignee name',
    Please_enter_consignee_name: 'Please enter consignee name',
    detailed_address: 'detailed address',
    Please_enter_detailed_address: 'Please enter detailed address',
    Is_it_set_to_default_address: 'Is it set to the default address?',
    Save_and_use: 'Save and use',
    name: 'name',
    tacitly_approve: 'tacitly approve',
    redact: 'redact',
    Invoice_management: 'Invoice management',
    Add_an_invoice: 'Add an invoice',
    Pack_up_all: 'Pack up all',
    Full_expansion: 'Full expansion',
    Security_settings: 'Security settings',
    Modify_mobile_phone_number: 'Modify mobile phone number',
    Login_password: 'Login password',
    Changing_the_login_Password: 'Changing the login Password',
    payment_password: 'payment password',
    Change_payment_password: 'Change payment password'

  },
  merchantworkbench: {
    Release_pre_sale: 'Release presale',
    Release_from_stock: 'Release from stock',
    Publish_auction: 'Publish auction',
    Release_Barter: 'Release Barter',
    back_homepage: 'back homepage',
    Release_management: 'Release management',
    Basic_information: 'Basic information',
    please_choose: 'please choose',
    Unit_of_measurement: 'Unit of measurement',
    producer: 'producer',
    Specification_settings: 'Specification settings',
    Delivery_date: 'Delivery date',
    Sale_price: 'Sale price',
    Property_picture: 'Property picture',
    Click_Add: 'Click Add',
    Rule_settings: 'Rule settings',
    Expiry_date_of_offer: 'Expiry date of offer',
    option_date: 'option date',
    Currency: 'Currency',
    RMB: 'RMB',
    USD: 'USD',
    online_payment: 'online payment',
    Permissible_overflow_ratio: 'Permissible overflow ratio',
    Maximum_10: 'Maximum 10%',
    Minimum_increment: 'Minimum increment',
    Unit: 'Unit',
    upload: 'Upload',
    replace: 'replace',
    Add: 'Add',
    Added_a_withholding_method: 'Added a withholding method',
    Please_enter_specification: 'Please enter specification',
    Please_enter_price: 'Please enter the price',
    The_specification: 'The specification is the packaging specification/shipping specification, and the price is the packaging price/shipping price',
    Resale_allowed: 'Resale allowed',
    Resale_is_not_allowed: 'Resale is not allowed',
    Number_of_days_in_advance: 'Number of days in advance',
    Settlement_warehouse: 'Settlement warehouse',
    Coffee_ettings: 'Coffee settings',
    Coffee_Estate: 'Coffee Estate',
    Manor_introduction: 'Manor introduction',
    grade: 'grade',
    authentication: 'authentication',
    cuppingReference: 'Cup reference',
    treeSpecies: 'treeSpecies',
    Planting_altitude: 'Planting altitude',
    metre: 'metre',
    Year_of_harvest: 'Year of harvest',
    processing_method: 'processing method',
    Triple_anaerobic: 'Triple anaerobic',
    Double_fermentation_in_the_sun: 'Double fermentation in the sun',
    Kombucha_hair_western_sun_exposure: 'Kombucha hair western sun exposure',
    solarization: 'solarization',
    Aerobic_fermentation_in_the_sun: 'Aerobic fermentation in the sun',
    Washing: 'Washing',
    Wash_with: 'Wash with water for 96 hours to ferment',
    wet_dig: 'wet dig',
    wet_dig_handle: 'wet dig handle',
    dense_handle: 'dense handle',
    dense_handle_Late_Receipt_Batch: 'dense handle Late Receipt Batch',
    other: 'other',
    Flavor_description: 'Flavor description',
    words: '1-200 words',
    Please_enter_a_flavor_description: 'Please enter a flavor description',
    Main_product_image: 'Main product image',
    Upload_up: 'Upload up to 5 images and click Change Image',
    Supports_JPG: 'Supports JPG, JPEG, PNG, GIF formats',
    Product_detail_page: 'Product detail page',
    Note_Please: 'Note: Please typeset carefully, when showing to customers, it will be strictly displayed according to this layout',
    submit: 'submit',
    Freight: 'Freight',
    domestic: 'domestic',
    foreign: 'foreign',
    Settlement_area: 'Settlement area',
    Please_select: 'Please select the settlement area',
    Choose_spot_or_pre_sale: 'Choose spot or pre-sale',
    Pre_sale_goods: 'Pre-sale goods',
    Please_select_a: 'Please select a commodity name',
    Delivery_time: 'Delivery time',
    Bidding_rules: 'Bidding rules',
    Auction_title: 'Auction title',
    please_enter: 'please enter',
    please_fill_in: 'please fill in',
    Total_number_of_bids: 'Total number of bids',
    Starting_unit_price: 'Starting unit price',
    Minimum_markup_unit: 'Minimum markup unit',
    Minimum_transaction_ratio: 'Minimum transaction ratio',
    Markdown_cycle: 'Markdown cycle',
    lowest_price: 'lowest price',
    content: 'content',
    detailed_introduction: 'detailed introduction',
    earnest_money_ratio: 'earnest money ratio',
    Auction_period: 'Auction period',
    publish: 'publish',
    Product_Inventory: 'Product Inventory',
    Effective_Date: 'Effective Date',
    Deposit: 'Deposit',
    Pay_deposit: 'Pay deposit',
    Goods_to_be_prepared: 'Goods to be prepared',
    Goods_to_be_shipped: 'Goods to be shipped',
    shipped: 'shipped',
    Deal_Close: 'Deal Close',
    Canceled: 'Canceled',
    buyer: 'buyer',
    Transaction_Status: 'Transaction Status',
    See_details: 'See details',
    order_management: 'order management',
    Order_details: 'Order details',
    User_ID: 'User ID',
    Shipping_Method: 'Shipping Method',
    None: 'None',
    Contract: 'Contract',
    Documents: 'Documents',
    Buyer_message: 'Buyer message',
    Fee_information: 'Fee information',
    Transferred_quantity: 'Transferred quantity',
    Stocking_quantity: 'Stocking quantity',
    Stock_Difference: 'Stock Difference',
    Logistics_fee: 'Logistics fee',
    accounts_payable: 'accounts payable',
    Paid: 'Paid',
    Merchandise_management: 'Merchandise management',
    On_the_shelf: 'On the shelf',
    Removed_from_shelves: 'Removed from shelves',
    merchandise_categories: 'merchandise categories',
    hit_the_shelf: 'hit the shelf',
    out_of_stock: 'out of stock',
    Audit_management: 'Audit management',
    pass_the_audit: 'pass the audit',
    unreviewed: 'unreviewed',
    not_pass: 'not pass',
    Withholding_to_be_confirmedL: 'Withholding to be confirmed',
    contact_information: 'contact information',
    Stock_quantity: 'Stock quantity',
    Republish: 'Republish',
    Product_ID: 'Product ID',
    release_time: 'release time'

  },
  special: {
    Zone_homepage: 'Zone homepage',
    Resale_hall: 'Resale hall',
    Auction_hall: 'Auction hall',
    Barter_hall: 'Barter hall',
    Standard_price_action: 'Standard price action',
    Please_select_a_variety: 'Please select a variety',
    The_transaction_price: 'The transaction price',
    region: 'region',
    Zone_recommendation: 'Zone recommendation',
    rush_to_purchase: 'purchase'

  }
}
